/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
@import "~plyr/dist/plyr.css";

@font-face {
    font-family: 'Cocogoose';
    font-weight: 300;
    src: url('./assets/fonts/Cocogoose\ Pro\ Light-trial.ttf');
}

@font-face {
    font-family: 'Cocogoose';
    font-weight: 200;
    src: url('./assets/fonts/Cocogoose\ Pro\ Ultralight-trial.ttf');
}

@font-face {
    font-family: 'Figtree';
    font-weight: 200;
    src: url('./assets/fonts/Figtree-Regular.ttf');
}

@font-face {
    font-family: 'Figtree';
    font-weight: 300;
    src: url('./assets/fonts/Figtree-Medium.ttf');
}

@font-face {
    font-family: 'Gilroy';
    font-weight: 500;
    src: url('./assets/fonts/Gilroy-ExtraBold.otf');
}

@font-face {
    font-family: 'MyriadPro';
    font-weight: 300;
    src: url('./assets/fonts/MyriadPro-Regular.otf');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 300;
    src: url('./assets/fonts/Poppins-Regular.ttf');
}

@font-face {
    font-family: 'Bobbleboddy';
    font-weight: bolder;
    src: url('./assets/fonts/Bobbleboddy_light.ttf');
}

.font-cocogoose{
    font-family: 'Cocogoose' !important;
}

.font-figtree{
    font-family: 'Figtree' !important;
}

.font-gilroy{
    font-family: 'Gilroy' !important;
}

.font-myriad{
    font-family: 'MyriadPro' !important;
}

.font-poppins{
    font-family: 'Poppins' !important;
}

.font-bobbleboddy{
    font-family: 'Bobbleboddy' !important;
}
// font-weight
.font-weight-thin{
    font-weight: 100 !important;
}
.font-weight-light{
    font-weight: 200 !important;
}
.font-weight-normal{
    font-weight: normal !important;
}
.font-weight-regular{
    font-weight: 500 !important;
}
.font-weight-bold{
    font-weight: bold !important;
}
.font-weight-bolder{
    font-weight: 900 !important;
}

.spinner-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}

// Plyr css
.plyr.plyr--full-ui.plyr--video{
    border-radius: 8px;
    margin-bottom: 0px;
}

.plyr .plyr__controls .plyr__controls__item.plyr__progress__container, .plyr__volume input[type=range]{
    min-width: 40px !important;
}

swiper-container{
    width: 100%;
    --swiper-pagination-bullet-inactive-color: var(--ion-color-medium, #dddddd);
    --swiper-pagination-color: var(--ion-color-primary, #7df4d0);
    --swiper-pagination-bullet-width: 40px;
    --swiper-pagination-bullet-border-radius: 6px;
    --swiper-pagination-top: 100%;
    margin-bottom: 20px;
    &::part(container){
        overflow: unset !important;
    }
    &::part(wrapper) {
        height: auto;
    }
    swiper-slide{
        img, video{
            object-fit: cover;
            width: 100%;
        }
    }
    &.hide-no-active{
        swiper-slide{
            &:not(.swiper-slide-active) {
                height: 0px;
            }
        }
    }
}

// ion-fab[vertical="bottom"][slot="fixed"] {
//     bottom: calc(var(--ion-safe-area-bottom, 6) + 6px);
// }

.media-preview{
    display: flex;
    overflow-x: scroll;
    flex-wrap: nowrap;
    scroll-behavior: smooth;
    --background: transparent;
    &:not(.slidable) {
        overflow:  auto !important;
    }
    .image-preview{
        position: relative;
        scroll-behavior: smooth;
        background: none;
        padding: 0px;
        margin-inline-end: 10px;
        flex: 0 0 90%;
        img{
            width:100%;
            max-width: 100%;
            height: 210px;
            max-height: 210px;
            border: 2px solid var(--ion-color-light, #DDD);
            border-radius: 10px;
        }
        ion-button{
            position: absolute;
            top: 0px;
            right: 0px;
            ion-icon{ 
                width: 1.3em;
                height: 1.3em;
            }
        }
    }
}

.can-go-back{
    .header-logo{
        display: none;
    }
}
ion-card{
    &.bg-none{
        --background: none;
    }
}

.pointer{
    cursor: pointer;
}

.flex{
    display: flex;
}

.no-wrap {
    text-overflow: clip !important;
    white-space: normal !important;
}
.hideFileInput{
    position: absolute;
    visibility: hidden;
    top: 20px;
}

.center{
    text-align: center;
    align-items: center;
    justify-content: center;
}

.font-w-500 {
    font-weight: 500;
}

.font-s-xs {
    font-size: 8px;
}

.font-s-sm {
    font-size: 12px;
}

.font-s-m {
    font-size: 15px;
}

.font-s-10 {
    font-size: 10px;
}

.font-s-20 {
    font-size: 20px;
}

.font-s-25 {
    font-size: 25px;
}

.font-s-40 {
    font-size: 40px;
}

// .tabs-inner{
//     margin-bottom: 70px !important
// }
.sticky-top{
    position: sticky;
    top: 0;
    z-index: 1000;
}

ion-item{
    --ion-font-family: "Gilroy";
    font-weight: 100;
    text-align: left;
    &:not(.ion-no-margin) {
        margin: 15px 0px;
    }
    &:not([native], .select-interface-option) {
        --background: none;
        --border-style: none;
        border: solid 2pt var(--ion-color-dark);
        border-radius: 40px;
        --color: var(--ion-color-primary);
        --placeholder-color: var(--ion-color-primary);
        ion-input{
            --placeholder-opacity: .7 !important;
            &.input-container{
                border-bottom: solid 2pt var(--ion-color-dark);
            }
        }
        ion-datetime-button{
            &::part(native){
                background: var(--ion-color-primary) !important;
                color: var(--ion-color-light);
                border-radius: 30px;
            }
        }
        ion-avatar{
            width: 60px;
            height: 60px;
            &.no-profile{
                --border-radius: 0;
            }
        }
        ion-checkbox{
            --ion-font-family: "Bobbleboddy";
            font-weight: 600;
            font-size: 11px;
            
        }
        ion-label{
            &.checkbox-font{
                --ion-font-family: "Bobbleboddy";
                font-weight: 600;
                font-size: 11px;
            }
        }
    }
    &.no-container{
        border: none;
    }
}
ion-button{
    --ion-font-family: "Bobbleboddy";
    letter-spacing: 3px;
    font-weight: 600;
}

// Reset margin and padding for the body
@media (min-width: 768px) {
    ion-app, ion-modal {
        --width: 475px; 
        margin: 0 auto; 
        .ion-page {
            max-width: 474px; 
            margin: 0 auto; 
        }
    }
}

ion-card[color=lite] {
    .plan_status {
        background: #E0d0ec;
    }
}

ion-card[color=supa] {
    .plan_status {
        background: #b5deec;
    }
}

ion-card[color=powa] {
    .plan_status {
        background: #ffdbdb;
    }
}

.img-100 {
    width: 100px;
    height: 100px;
}

.img-70 {
    width: 70px;
    height: 70px;
}

.img-80 {
    width: 80px;
    height: 80px;
}

.img-30 {
    width: 30px;
    height: 30px;
}

.delete-account {
    margin-top: 70px;
}

.alert-wrapper > .alert-button-group > .do-not-delete-account {
    background-color: green;
    color: white;
    font-weight: bold;
}

.alert-wrapper > .alert-button-group > .delete-account {
    background-color: red;
    color: white;
    font-weight: normal;
}


// hide these element if keyboard is open
html.keyboard-open{
    ion-footer{
        &.copy-right-footer{
            display: none;
        }
        &.footer-inside{
            display: none;
        }
    }
    ion-tab-bar{
        display: none !important;
    }
}
// RTL Styles
[dir=rtl] {
    ion-label, ion-input {
        text-align: start;
    }

    .banner{
        .title{
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
            border-top-left-radius: 40px;
            border-bottom-left-radius: 40px;
        }
    }
} 

.bottom-sheet-modal {
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --height: auto;
    --max-height: 99%;
    --overflow: scroll;
}

.alert-modal {
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --height: auto;
    --width: 80%;
    --max-width: 310px;
    --max-height: 90%;
    --overflow: scroll;
}

.confirm-modal {
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --height: auto;
    --width: 80%;
    --max-width: 280px;
    --max-height: 90%;
    --overflow: scroll;
}

ion-title{
    &[full],
    &.full{
        padding-inline: 3px !important
    }
}

.new-friends-modal{
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --height: auto;
    --max-height: 92%;
    --width: 80%;
    --max-width: 96%;
    --overflow: scroll;
}

.vip-confirm-modal {
    --width: 55%; /* Adjust the width as needed */
    --border-radius: 8px;
    --background: white;
    --box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    --backdrop-opacity: 0.6;
    --height: auto;
    --width: 80%;
    --max-width: 230px;
    --max-height: 90%;
}

.vip-confirm-modal .modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--width);
    height: var(--height);
    border-radius: var(--border-radius);
    background: var(--background);
    box-shadow: var(--box-shadow);
    margin: auto;
}

.position-relative {
    position: relative;
}

// Add some gap for flex
@each $key in 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16 {
    .gap-#{$key} {
        gap: #{$key}px !important;
        &.em {
            gap: #{$key}em !important;
        }
    }
    .font-size-#{$key} {
        font-size: #{$key}px !important;
        &.em {
            font-size: #{$key}em !important;
        }
    }
    .radius-#{$key} {
        border-radius: #{$key}px !important;
        --border-radius: #{$key}px !important;
        &.em {
            border-radius: #{$key}em !important;
            --border-radius: #{$key}em !important;
        }
    }
    @each $margin in (margin, margin-block, margin-inline, margin-top, margin-bottom, margin-left, margin-right) {
        @if $key == 0 {
            .#{$margin}-auto {
                #{$margin}: auto;
            }
        }
        .#{$margin}-#{$key} {
            #{$margin}: #{$key}px;
            &.em {
                #{$margin}: #{$key}em;
            }
        }
    }
    @each $padding in (padding, padding-block, padding-inline, padding-top, padding-bottom, padding-left, padding-right) {
        @if $key == 0 {
            .#{$padding}-auto {
                #{$padding}: auto;
            }
        }
        .#{$padding}-#{$key} {
            #{$padding}: #{$key}px;
            &.em {
                #{$padding}: #{$key}em;
            }
        }
    }
}

ion-select::part(icon) {
    margin-left: auto;
}

.flex-column {
    flex-direction: column;
}
.flex-row {
    flex-direction: row;
}


form, .form, [formGroup] {
    swiper-container{
        width: 100%;
        --swiper-pagination-bullet-inactive-color: rgba(var(--ion-color-medium-rgb), 0.5);
        --swiper-pagination-color: rgba(var(--ion-color-medium-rgb), 1);
        swiper-slide{
            img, video{
                object-fit: cover;
                width: 100%;
                height: 300px;
            }
        }
    }
    
    ion-item{
        --ion-font-family: "Bobbleboddy";
        font-weight: 800;
        letter-spacing: 2px;
        --color: var(--ion-color-secondary);
        --placeholder-color: var(--ion-color-secondary);
        
    
        ion-label{
            ion-button{
                box-shadow: 0px 4px 10px var(--ion-color-light-shade);
                border-radius: 10px;
                margin-left: 30px;
            }
        }
    
        &.item-shadow{
            box-shadow: 0px 4px 10px var(--ion-color-light-shade);
        }
    
        &.textarea{
            border-radius: 10px;
            --ion-font-family: "Bobbleboddy";
            font-weight: 600;
            letter-spacing: 1px;
            font-size: 1.13em;
            color: var(--ion-color-secondary);
        }
    }
    
    .media-preview{
        display: flex;
        overflow-x: scroll;
        flex-wrap: nowrap;
        scroll-behavior: smooth;
        --background: transparent;
        &:not(.slidable) {
            overflow:  auto !important;
        }
        .image-preview{
            position: relative;
            scroll-behavior: smooth;
            background: none;
            padding: 0px;
            margin-inline-end: 10px;
            flex: 0 0 90%;
            img{
                width:100%;
                max-width: 100%;
                height: 210px;
                max-height: 210px;
                border: 2px solid var(--ion-color-light, #DDD);
                border-radius: 10px;
            }
            ion-button{
                position: absolute;
                top: 0px;
                right: 0px;
                ion-icon{ 
                    width: 1.3em;
                    height: 1.3em;
                }
            }
        }
    }
    
    @media (prefers-color-scheme: dark) {
        ion-item{
            --color: var(--ion-color-dark);
            --placeholder-color: var(--ion-color-dark);
    
            &.textarea{
                color: var(--ion-color-dark);
            }
        }
    }
}

.paypal-checkout-sandbox-iframe {
    border: 0;
}

html.plt-ios .paypal-checkout-sandbox-iframe {
    top: env(safe-area-inset-top) !important;
}
