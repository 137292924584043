// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #047a79;
	--ion-color-primary-rgb: 4,122,121;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #046b6a;
	--ion-color-primary-tint: #1d8786;

  /** secondary **/
  --ion-color-secondary: #1E2548;
	--ion-color-secondary-rgb: 30,37,72;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #1a213f;
	--ion-color-secondary-tint: #353b5a;

  /** tertiary **/
  --ion-color-tertiary: #6C150E;
	--ion-color-tertiary-rgb: 108,21,14;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #5f120c;
	--ion-color-tertiary-tint: #7b2c26;

  /** quaternary **/
  --ion-color-quaternary: #255d89;
	--ion-color-quaternary-rgb: 37,93,137;
	--ion-color-quaternary-contrast: #ffffff;
	--ion-color-quaternary-contrast-rgb: 255,255,255;
	--ion-color-quaternary-shade: #215279;
	--ion-color-quaternary-tint: #3b6d95;

  /** quinary **/
  --ion-color-quinary: #00855D;
	--ion-color-quinary-rgb: 0,133,93;
	--ion-color-quinary-contrast: #ffffff;
	--ion-color-quinary-contrast-rgb: 255,255,255;
	--ion-color-quinary-shade: #007552;
	--ion-color-quinary-tint: #1a916d;

  /** senary **/
  --ion-color-senary: #116950;
	--ion-color-senary-rgb: 17,105,80;
  --ion-color-senary-contrast: #ffffff;
  --ion-color-senary-contrast-rgb: 255,255,255;
  --ion-color-senary-shade: #0b4736;
  --ion-color-senary-tint: #256250;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #f2a618;
	--ion-color-warning-rgb: 242,166,24;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255,255,255;
	--ion-color-warning-shade: #d59215;
	--ion-color-warning-tint: #f3af2f;

  /** danger **/
  --ion-color-danger: #e52420;
	--ion-color-danger-rgb: 229,36,32;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #ca201c;
	--ion-color-danger-tint: #e83a36;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** grey **/
  --ion-color-grey: #383838;
	--ion-color-grey-rgb: 56,56,56;
	--ion-color-grey-contrast: #ffffff;
	--ion-color-grey-contrast-rgb: 255,255,255;
	--ion-color-grey-shade: #313131;
	--ion-color-grey-tint: #4c4c4c;

  /** medium **/
  --ion-color-medium: #969696;
	--ion-color-medium-rgb: 150,150,150;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #848484;
	--ion-color-medium-tint: #a1a1a1;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** lite **/
	--ion-color-lite: #A946f2;
	--ion-color-lite-rgb: 169,70,242;
	--ion-color-lite-contrast: #000000;
	--ion-color-lite-contrast-rgb: 0,0,0;
	--ion-color-lite-shade: #953ed5;
	--ion-color-lite-tint: #b259f3;

  /** supa **/
  --ion-color-supa: #25c6fb;
	--ion-color-supa-rgb: 37,198,251;
	--ion-color-supa-contrast: #000000;
	--ion-color-supa-contrast-rgb: 0,0,0;
	--ion-color-supa-shade: #21aedd;
	--ion-color-supa-tint: #3bccfb;

  /** powa **/
  --ion-color-powa: #c00000;
	--ion-color-powa-rgb: 192,0,0;
	--ion-color-powa-contrast: #ffffff;
	--ion-color-powa-contrast-rgb: 255,255,255;
	--ion-color-powa-shade: #a90000;
	--ion-color-powa-tint: #c61a1a;

  /** lite text **/
  --ion-color-lite_text: #411165;
	--ion-color-lite_text-rgb: 65,17,101;
	--ion-color-lite_text-contrast: #ffffff;
	--ion-color-lite_text-contrast-rgb: 255,255,255;
	--ion-color-lite_text-shade: #390f59;
	--ion-color-lite_text-tint: #542974;

  /** supa text **/
  --ion-color-supa_text: #175D74;
	--ion-color-supa_text-rgb: 23,93,116;
	--ion-color-supa_text-contrast: #ffffff;
	--ion-color-supa_text-contrast-rgb: 255,255,255;
	--ion-color-supa_text-shade: #145266;
	--ion-color-supa_text-tint: #2e6d82;

  /** powa text **/
  --ion-color-powa_text: #2A0000;
	--ion-color-powa_text-rgb: 42,0,0;
	--ion-color-powa_text-contrast: #ffffff;
	--ion-color-powa_text-contrast-rgb: 255,255,255;
	--ion-color-powa_text-shade: #250000;
	--ion-color-powa_text-tint: #3f1a1a;

  --plyr-video-control-background-hover: #047a79;
  --plyr-color-main: #047a79;

  /** blue **/
  --ion-color-blue: #345D83;
  --ion-color-blue-shade: #587FA3;

  --activities-slider-background-color-top: #15675d;
  --activities-slider-background-color-bottom: #1b3852;

  /** red **/
  --ion-color-red: #C5282A;
  --ion-color-red-shade: #A82B25;
}

.ion-color-quaternary {
	--ion-color-base: var(--ion-color-quaternary);
	--ion-color-base-rgb: var(--ion-color-quaternary-rgb);
	--ion-color-contrast: var(--ion-color-quaternary-contrast);
	--ion-color-contrast-rgb: var(--ion-color-quaternary-contrast-rgb);
	--ion-color-shade: var(--ion-color-quaternary-shade);
	--ion-color-tint: var(--ion-color-quaternary-tint);
}

.ion-color-quinary {
	--ion-color-base: var(--ion-color-quinary);
	--ion-color-base-rgb: var(--ion-color-quinary-rgb);
	--ion-color-contrast: var(--ion-color-quinary-contrast);
	--ion-color-contrast-rgb: var(--ion-color-quinary-contrast-rgb);
	--ion-color-shade: var(--ion-color-quinary-shade);
	--ion-color-tint: var(--ion-color-quinary-tint);
}

.ion-color-senary {
	--ion-color-base: var(--ion-color-senary);
	--ion-color-base-rgb: var(--ion-color-senary-rgb);
	--ion-color-contrast: var(--ion-color-senary-contrast);
	--ion-color-contrast-rgb: var(--ion-color-senary-contrast-rgb);
	--ion-color-shade: var(--ion-color-senary-shade);
	--ion-color-tint: var(--ion-color-senary-tint);
}

.ion-color-grey {
	--ion-color-base: var(--ion-color-grey);
	--ion-color-base-rgb: var(--ion-color-grey-rgb);
	--ion-color-contrast: var(--ion-color-grey-contrast);
	--ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
	--ion-color-shade: var(--ion-color-grey-shade);
	--ion-color-tint: var(--ion-color-grey-tint);
}

.ion-color-lite {
	--ion-color-base: var(--ion-color-lite);
	--ion-color-base-rgb: var(--ion-color-lite-rgb);
	--ion-color-contrast: var(--ion-color-lite-contrast);
	--ion-color-contrast-rgb: var(--ion-color-lite-contrast-rgb);
	--ion-color-shade: var(--ion-color-lite-shade);
	--ion-color-tint: var(--ion-color-lite-tint);
}

.ion-color-supa {
	--ion-color-base: var(--ion-color-supa);
	--ion-color-base-rgb: var(--ion-color-supa-rgb);
	--ion-color-contrast: var(--ion-color-supa-contrast);
	--ion-color-contrast-rgb: var(--ion-color-supa-contrast-rgb);
	--ion-color-shade: var(--ion-color-supa-shade);
	--ion-color-tint: var(--ion-color-supa-tint);
}

.ion-color-powa {
	--ion-color-base: var(--ion-color-powa);
	--ion-color-base-rgb: var(--ion-color-powa-rgb);
	--ion-color-contrast: var(--ion-color-powa-contrast);
	--ion-color-contrast-rgb: var(--ion-color-powa-contrast-rgb);
	--ion-color-shade: var(--ion-color-powa-shade);
	--ion-color-tint: var(--ion-color-powa-tint);
}

.ion-color-lite_text {
	--ion-color-base: var(--ion-color-lite_text);
	--ion-color-base-rgb: var(--ion-color-lite_text-rgb);
	--ion-color-contrast: var(--ion-color-lite_text-contrast);
	--ion-color-contrast-rgb: var(--ion-color-lite_text-contrast-rgb);
	--ion-color-shade: var(--ion-color-lite_text-shade);
	--ion-color-tint: var(--ion-color-lite_text-tint);
}

.ion-color-supa_text {
	--ion-color-base: var(--ion-color-supa_text);
	--ion-color-base-rgb: var(--ion-color-supa_text-rgb);
	--ion-color-contrast: var(--ion-color-supa_text-contrast);
	--ion-color-contrast-rgb: var(--ion-color-supa_text-contrast-rgb);
	--ion-color-shade: var(--ion-color-supa_text-shade);
	--ion-color-tint: var(--ion-color-supa_text-tint);
}

.ion-color-powa_text {
	--ion-color-base: var(--ion-color-powa_text);
	--ion-color-base-rgb: var(--ion-color-powa_text-rgb);
	--ion-color-contrast: var(--ion-color-powa_text-contrast);
	--ion-color-contrast-rgb: var(--ion-color-powa_text-contrast-rgb);
	--ion-color-shade: var(--ion-color-powa_text-shade);
	--ion-color-tint: var(--ion-color-powa_text-tint);
}


@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #047a79;
    --ion-color-primary-rgb: 4,122,121;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #046b6a;
    --ion-color-primary-tint: #1d8786;

    --ion-color-secondary: #1E2548;
    --ion-color-secondary-rgb: 30,37,72;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #1a213f;
    --ion-color-secondary-tint: #353b5a;

    --ion-color-tertiary: #6C150E;
    --ion-color-tertiary-rgb: 108,21,14;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5f120c;
    --ion-color-tertiary-tint: #7b2c26;

    --ion-color-quaternary: #255d89;
    --ion-color-quaternary-rgb: 37,93,137;
    --ion-color-quaternary-contrast: #ffffff;
    --ion-color-quaternary-contrast-rgb: 255,255,255;
    --ion-color-quaternary-shade: #215279;
    --ion-color-quaternary-tint: #3b6d95;

    --ion-color-quinary: #00855D;
    --ion-color-quinary-rgb: 0,133,93;
    --ion-color-quinary-contrast: #ffffff;
    --ion-color-quinary-contrast-rgb: 255,255,255;
    --ion-color-quinary-shade: #007552;
    --ion-color-quinary-tint: #1a916d;

    --ion-color-senary: #116950;
	  --ion-color-senary-rgb: 17,105,80;
    --ion-color-senary-contrast: #ffffff;
    --ion-color-senary-contrast-rgb: 255,255,255;
    --ion-color-senary-shade: #0b4736;
    --ion-color-senary-tint: #256250;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255,255,255;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #f2a618;
    --ion-color-warning-rgb: 242,166,24;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255,255,255;
    --ion-color-warning-shade: #d59215;
    --ion-color-warning-tint: #f3af2f;

    --ion-color-danger: #e52420;
    --ion-color-danger-rgb: 229,36,32;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #ca201c;
    --ion-color-danger-tint: #e83a36;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-grey: #383838;
    --ion-color-grey-rgb: 56,56,56;
    --ion-color-grey-contrast: #ffffff;
    --ion-color-grey-contrast-rgb: 255,255,255;
    --ion-color-grey-shade: #313131;
    --ion-color-grey-tint: #4c4c4c;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    --plyr-video-control-background-hover: #047a79;
    --plyr-color-main: #047a79;

    --ion-color-blue: #345D83;
    --ion-color-blue-shade: #587FA3;

    --activities-slider-background-color-top: #15675d;
    --activities-slider-background-color-bottom: #1b3852;

    --ion-color-red: #C5282A;
    --ion-color-red-shade: #A82B25;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}

html {
  /* 
   * For more information on dynamic font scaling, visit the documentation:
   * https://ionicframework.com/docs/layout/dynamic-font-scaling
   */
  --ion-dynamic-font: var(--ion-default-dynamic-font);
}